$rc2-spacing-0: 0;
$rc2-spacing-4xs: 2px;
$rc2-spacing-3xs: 4px;
$rc2-spacing-xxs: 8px;
$rc2-spacing-xs: 12px;
$rc2-spacing-s: 16px;
$rc2-spacing-m: 20px;
$rc2-spacing-l: 24px;
$rc2-spacing-xl: 32px;
$rc2-spacing-xxl: 40px;
$rc2-spacing-3xl: 48px;
$rc2-spacing-4xl: 56px;
$rc2-spacing-5xl: 64px;
$rc2-spacing-6xl: 72px;

$rc2-header-height: 80px;
$rc2-navbar-height: 44px;
$rc2-footer-links-height: 3.33rem;
$rc2-footer-logo-height: 120px;
$rc2-slider-width-expanded: 240px;
// #{''} added to provides spaces in calc
$rc2-main-content-size: #{100vh} #{''} - #{''} #{$rc2-header-height} #{''} - #{''} #{$rc2-navbar-height};
