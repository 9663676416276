@import 'globals/colors';
@import 'globals/spacings';
@import 'globals/z-index';

.rc2-overlay {
  &--header {
    position: relative;
    display: flex;
    min-width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    background-color: $rc2-color-primary;
    border-radius: 12px;
  }

  &--row {
    position: relative;
  }

  &--col {
    position: absolute;
    z-index: $z-index-element-above;
    background: $rc2-white;
  }

  &--items {
    padding: 5px;
    letter-spacing: 0.4px;
    line-height: 21px;

    &:nth-child(even) {
      background-color: $rc2-grey-100;
    }

    &:nth-child(odd) {
      background-color: $rc2-white;
    }
  }

  &--icon {
    padding: $rc2-spacing-3xs;
    color: $rc2-white;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 18px;
    text-align: center;
  }

  &--spinner {
    margin: 30px auto;
  }

  &--template {
    width: 324px;
    max-height: 250px;
    overflow-y: auto;
    padding: $rc2-spacing-xxs;
    background-color: $rc2-white;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  }

  &--template--linked-object {
    width: 324px;
    background-color: $rc2-white;
    padding: 0px;
    recall2-loading-spinner {
      padding: 12px;
    }
  }

  &--hide {
    display: none;
    width: 0;
    height: 0;
  }

  &--filter {
    &--template {
      width: auto;
      border-top-color: $rc2-color-secondary;
      background-color: $rc2-white;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    }

    &--header {
      display: inline-block;
      width: auto;
    }

    &--text-filter {
      &--width {
        width: 358px;
      }
    }
  }
}

//---------------- Add new user Styling ----------------
.add-new-user-modal {
  display: block;
  width: 690px !important;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}
