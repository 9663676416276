@use 'globals/colors' as *;
@use 'globals/spacings' as *;
@use 'globals/typography' as *;

mat-optgroup {
  display: block;
  border-bottom: 1px solid $rc2-grey-200-tertiary;

  .mat-optgroup-label {
    @include body-s;
    @include font-bold;

    display: flex;
    align-items: center;
    padding: $rc2-spacing-xxs $rc2-spacing-s;
    margin-top: $rc2-spacing-xxs;
    color: $rc2-grey-900;
  }

  mat-option:last-child {
    margin-bottom: $rc2-spacing-xxs;
  }
}

.mat-optgroup .mat-option:not(.mat-option-multiple) {
  padding: $rc2-spacing-xxs $rc2-spacing-s !important;
}

.mat-mdc-autocomplete-panel,
mat-optgroup {
  .mat-option {
    @include body-m;

    &:hover:not(.mat-option-disabled) {
      background-color: $rc2-grey-100;
    }
  }
}

.show-error-input-on-tooltip {
  position: relative;
  color: $rc2-red-800-primary !important;

  &::-webkit-input-placeholder {
    color: $rc2-red-800-primary !important;
  }

  &-warning-icon {
    position: absolute;
    top: $rc2-spacing-xxs;
    right: $rc2-spacing-3xl;
    color: $rc2-red-800-primary;
    text-align: right;
  }
}
