$z-index-xxl: 1000;
$z-index-xl: 900;
$z-index-l: 800;
$z-index-m: 700;
$z-index-s: 600;
$z-index-xs: 500;
$z-index-xxs: 400;
$z-index-toast: $z-index-xxl;
$z-index-modal: $z-index-xl;
$z-index-side-sheet: $z-index-l;
$z-index-app-navigation: $z-index-m;
$z-index-header: $z-index-s;
$z-index-stepper-navigation: $z-index-xs;
$z-index-element-above: $z-index-xxs;
