/* stylelint-disable declaration-no-important */
@use 'globals/colors' as *;
@use 'globals/fonts' as *;
@use 'globals/spacings' as *;

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: #34393b;
  --mdc-dialog-supporting-text-weight: 400;

  .mat-mdc-dialog-surface {
    padding: 24px !important;

    .footer {
      padding-bottom: 0;
    }
  }
}

.mat-mdc-dialog-content,
.mat-mdc-dialog-title,
.mat-mdc-dialog-close {
  padding: 0 !important;
  overflow-x: hidden !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.modal-backdrop {
  background-color: $rc2-backdrop;
}

.mdc-dialog__actions {
  border-top: unset !important;
}

.modal-panel--no-spacing {
  .mat-mdc-dialog-surface {
    padding: 0 !important;
  }
}
