@use 'style/rc2-main' as *;

body {
  @include body-m;
}

.rc2-mar {
  &--left--auto {
    margin-left: auto;
  }

  &--right--auto {
    margin-right: auto;
  }
}

.i-close-small::after {
  position: relative;
  top: -2px;
  margin: 0 8px;
  font-size: 11.5px;
  font-weight: 800;
}

.action-bar {
  display: flex;
  justify-content: flex-end;

  &__button {
    margin-left: $rc2-spacing-s;

    &--left {
      margin-right: auto;
    }
  }
}

.overflow-visible {
  overflow: visible;
}

.cdk-overlay-container {
  background-color: transparent !important;
}

.cdk-global-overlay-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-cancel-modal {
  display: block;
  overflow: hidden;
  width: 461px !important;
  margin-right: auto;
  margin-left: auto;
  background: $rc2-white;
  border-radius: 0 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  .mat-mdc-dialog-title {
    color: #34393b;
    font-family: $rc2-font-bold;
    font-size: 20px;
    letter-spacing: 0.6px;
    line-height: 25px;
  }

  .mat-mdc-dialog-content {
    color: #34393b;
    font-family: $rc2-font-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
  }
}

//---------------- Send Modal Styling ----------------

.form-send-backdrop {
  background-color: $rc2-backdrop;
}

.form-category {
  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
  }
}

.continue-form-button {
  padding: 12px;
  border: 0;
  background-color: transparent;
  color: #004666;
  text-transform: none;

  &:hover {
    background-color: #edeff2;
  }
}

/* ------------------------ Animation Styles ---------------------------- */
.modal-backend-animation {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 7px;
  margin-top: 24px;
  color: $rc2-white;
  font-size: 14px;
  letter-spacing: 0.52px;
  line-height: 24px;
  text-align: center;
}

.show-backend-animation,
.show-backend-animation:hover {
  animation: backendRequestAnimate 1.2s both infinite ease-out;
  background: linear-gradient(to right, #004666 50%, #037da6 50%);
  background-position: left bottom;
  background-size: 200% 100%;
  color: $rc2-white !important; // we need to ensure that the color of text will always be white(visible), because we animate here the background color(dark blue)
}

@keyframes backendRequestAnimate {
  0% {
    background-position: right bottom;
  }

  100% {
    background-position: left bottom;
  }
}

.rc2-separator {
  width: 100%;
  border-bottom: 1px solid $rc2-grey-200-tertiary;
}

/* --------------- Overwrite browser defaults  ------------------- */
*:focus {
  outline: none; // default is blue
}

button {
  background-color: transparent; // in Firefox default is grey
}
