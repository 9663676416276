@import './../globals/spacings';

.rc2 {
  &-pad {
    &--no {
      padding: 0;
    }

    &--3xs {
      padding: $rc2-spacing-3xs;
    }

    &--xxs,
    &--2xs {
      padding: $rc2-spacing-xxs;
    }

    &--xs {
      padding: $rc2-spacing-xs;
    }

    &--s {
      padding: $rc2-spacing-s;
    }

    &--m {
      padding: $rc2-spacing-m;
    }

    &--l {
      padding: $rc2-spacing-l;
    }

    &--xl {
      padding: $rc2-spacing-xl;
    }

    &--xxl,
    &--2xl {
      padding: $rc2-spacing-xxl;
    }

    &--3xl {
      padding: $rc2-spacing-3xl;
    }

    &--4xl {
      padding: $rc2-spacing-4xl;
    }

    &--5xl {
      padding: $rc2-spacing-5xl;
    }

    &--6xl {
      padding: $rc2-spacing-6xl;
    }

    &--top {
      &--0 {
        padding-top: $rc2-spacing-0;
      }

      &--4xs {
        padding-top: $rc2-spacing-4xs;
      }

      &--3xs {
        padding-top: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        padding-top: $rc2-spacing-xxs;
      }

      &--xs {
        padding-top: $rc2-spacing-xs;
      }

      &--s {
        padding-top: $rc2-spacing-s;
      }

      &--m {
        padding-top: $rc2-spacing-m;
      }

      &--l {
        padding-top: $rc2-spacing-l;
      }

      &--xl {
        padding-top: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        padding-top: $rc2-spacing-xxl;
      }

      &--3xl {
        padding-top: $rc2-spacing-3xl;
      }

      &--4xl {
        padding-top: $rc2-spacing-4xl;
      }

      &--5xl {
        padding-top: $rc2-spacing-5xl;
      }

      &--6xl {
        padding-top: $rc2-spacing-6xl;
      }
    }

    &--bottom {
      &--0 {
        padding-bottom: $rc2-spacing-0;
      }

      &--4xs {
        padding-bottom: $rc2-spacing-4xs;
      }

      &--3xs {
        padding-bottom: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        padding-bottom: $rc2-spacing-xxs;
      }

      &--xs {
        padding-bottom: $rc2-spacing-xs;
      }

      &--s {
        padding-bottom: $rc2-spacing-s;
      }

      &--m {
        padding-bottom: $rc2-spacing-m;
      }

      &--l {
        padding-bottom: $rc2-spacing-l;
      }

      &--xl {
        padding-bottom: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        padding-bottom: $rc2-spacing-xxl;
      }

      &--3xl {
        padding-bottom: $rc2-spacing-3xl;
      }

      &--4xl {
        padding-bottom: $rc2-spacing-4xl;
      }

      &--5xl {
        padding-bottom: $rc2-spacing-5xl;
      }

      &--6xl {
        padding-bottom: $rc2-spacing-6xl;
      }
    }

    &--right {
      &--0 {
        padding-right: $rc2-spacing-0;
      }

      &--4xs {
        padding-right: $rc2-spacing-4xs;
      }

      &--3xs {
        padding-right: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        padding-right: $rc2-spacing-xxs;
      }

      &--xs {
        padding-right: $rc2-spacing-xs;
      }

      &--s {
        padding-right: $rc2-spacing-s;
      }

      &--m {
        padding-right: $rc2-spacing-m;
      }

      &--l {
        padding-right: $rc2-spacing-l;
      }

      &--xl {
        padding-right: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        padding-right: $rc2-spacing-xxl;
      }

      &--3xl {
        padding-right: $rc2-spacing-3xl;
      }

      &--4xl {
        padding-right: $rc2-spacing-4xl;
      }

      &--5xl {
        padding-right: $rc2-spacing-5xl;
      }

      &--6xl {
        padding-right: $rc2-spacing-6xl;
      }
    }

    &--left {
      &--0 {
        padding-left: $rc2-spacing-0;
      }

      &--4xs {
        padding-left: $rc2-spacing-4xs;
      }

      &--3xs {
        padding-left: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        padding-left: $rc2-spacing-xxs;
      }

      &--xs {
        padding-left: $rc2-spacing-xs;
      }

      &--s {
        padding-left: $rc2-spacing-s;
      }

      &--m {
        padding-left: $rc2-spacing-m;
      }

      &--l {
        padding-left: $rc2-spacing-l;
      }

      &--xl {
        padding-left: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        padding-left: $rc2-spacing-xxl;
      }

      &--3xl {
        padding-left: $rc2-spacing-3xl;
      }

      &--4xl {
        padding-left: $rc2-spacing-4xl;
      }

      &--5xl {
        padding-left: $rc2-spacing-5xl;
      }

      &--6xl {
        padding-left: $rc2-spacing-6xl;
      }
    }
  }

  //--------------
  &-mar {
    &--no {
      margin: 0;
    }

    &--3xs {
      margin: $rc2-spacing-3xs;
    }

    &--xxs,
    &--2xs {
      margin: $rc2-spacing-xxs;
    }

    &--xs {
      margin: $rc2-spacing-xs;
    }

    &--s {
      margin: $rc2-spacing-s;
    }

    &--m {
      margin: $rc2-spacing-m;
    }

    &--l {
      margin: $rc2-spacing-l;
    }

    &--xl {
      margin: $rc2-spacing-xl;
    }

    &--xxl,
    &--2xl {
      margin: $rc2-spacing-xxl;
    }

    &--3xl {
      margin: $rc2-spacing-3xl;
    }

    &--4xl {
      margin: $rc2-spacing-4xl;
    }

    &--5xl {
      margin: $rc2-spacing-5xl;
    }

    &--top {
      &--0 {
        margin-top: $rc2-spacing-0;
      }

      &--3xs {
        margin-top: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        margin-top: $rc2-spacing-xxs;
      }

      &--xs {
        margin-top: $rc2-spacing-xs;
      }

      &--s {
        margin-top: $rc2-spacing-s;
      }

      &--m {
        margin-top: $rc2-spacing-m;
      }

      &--l {
        margin-top: $rc2-spacing-l;
      }

      &--xl {
        margin-top: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        margin-top: $rc2-spacing-xxl;
      }

      &--3xl {
        margin-top: $rc2-spacing-3xl;
      }

      &--4xl {
        margin-top: $rc2-spacing-4xl;
      }

      &--5xl {
        margin-top: $rc2-spacing-5xl;
      }
    }

    &--bottom {
      &--0 {
        margin-bottom: $rc2-spacing-0;
      }

      &--4xs {
        margin-bottom: $rc2-spacing-4xs;
      }

      &--3xs {
        margin-bottom: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        margin-bottom: $rc2-spacing-xxs;
      }

      &--xs {
        margin-bottom: $rc2-spacing-xs;
      }

      &--s {
        margin-bottom: $rc2-spacing-s;
      }

      &--m {
        margin-bottom: $rc2-spacing-m;
      }

      &--l {
        margin-bottom: $rc2-spacing-l;
      }

      &--xl {
        margin-bottom: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        margin-bottom: $rc2-spacing-xxl;
      }

      &--3xl {
        margin-bottom: $rc2-spacing-3xl;
      }

      &--4xl {
        margin-bottom: $rc2-spacing-4xl;
      }

      &--5xl {
        margin-bottom: $rc2-spacing-5xl;
      }
    }

    &--right {
      &--0 {
        margin-right: $rc2-spacing-0;
      }

      &--3xs {
        margin-right: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        margin-right: $rc2-spacing-xxs;
      }

      &--xs {
        margin-right: $rc2-spacing-xs;
      }

      &--s {
        margin-right: $rc2-spacing-s;
      }

      &--m {
        margin-right: $rc2-spacing-m;
      }

      &--l {
        margin-right: $rc2-spacing-l;
      }

      &--xl {
        margin-right: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        margin-right: $rc2-spacing-xxl;
      }

      &--3xl {
        margin-right: $rc2-spacing-3xl;
      }

      &--4xl {
        margin-right: $rc2-spacing-4xl;
      }

      &--5xl {
        margin-right: $rc2-spacing-5xl;
      }
    }

    &--left {
      &--0 {
        margin-left: $rc2-spacing-0;
      }

      &--3xs {
        margin-left: $rc2-spacing-3xs;
      }

      &--xxs,
      &--2xs {
        margin-left: $rc2-spacing-xxs;
      }

      &--xs {
        margin-left: $rc2-spacing-xs;
      }

      &--s {
        margin-left: $rc2-spacing-s;
      }

      &--m {
        margin-left: $rc2-spacing-m;
      }

      &--l {
        margin-left: $rc2-spacing-l;
      }

      &--xl {
        margin-left: $rc2-spacing-xl;
      }

      &--xxl,
      &--2xl {
        margin-left: $rc2-spacing-xxl;
      }

      &--3xl {
        margin-left: $rc2-spacing-3xl;
      }

      &--4xl {
        margin-left: $rc2-spacing-4xl;
      }

      &--5xl {
        margin-left: $rc2-spacing-5xl;
      }
    }
  }
}
