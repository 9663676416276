@import 'globals/colors';

// We use important because ng-deep outside the view .scss doesn't work
.rc2-delete-button button {
  color: $rc2-color-danger !important;

  &.disabled {
    color: $rc2-grey-400-secondary !important;
  }

  &:active,
  &:hover {
    background: rgba($rc2-color-danger, 0.1) !important;
  }
}

.rc2-delete-icon-button button {
  &:hover {
    color: $rc2-red-800-primary !important;
  }
}
