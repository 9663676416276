@import 'globals/colors';
@import 'globals/spacings';

$animation-primary-dark: $rc2-color-secondary;
$animation-primary-bright: #037da6;

.shepherd-button {
  display: flex;
  min-width: 96px;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 3px;
  margin-right: 0.5rem;
  background: $rc2-color-primary;
  color: var(--color, $rc2-white);
  cursor: pointer;
  transition: all 0.5s ease;

  &:not(:disabled):hover,
  &:active {
    background: $rc2-petrol-900;
  }

  &.disabled {
    background: $rc2-grey-400-secondary;
    color: var(--color, $rc2-grey-200-tertiary);
    cursor: not-allowed;
  }

  &.next-button::after {
    display: block;
    margin-left: 10px;
    content: '\276F';
  }
}

.shepherd-button-secondary {
  display: flex;
  min-width: 96px;
  align-items: center;
  background: $rc2-white;
  color: var(--color, $rc2-color-primary);
  cursor: pointer;

  &:not(:disabled):hover {
    background: rgba($rc2-color-primary, 0.1);
  }

  &.disabled {
    color: var(--color, $rc2-grey-400-secondary);
    cursor: not-allowed;
  }
}

.shepherd-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;

  .shepherd-progress-wrapper {
    width: 100%;
    margin-right: 0.75rem;

    .shepherd-progress {
      font-size: 0.8rem;
    }

    span {
      color: $rc2-grey-400-secondary;
    }
  }
}

.shepherd-progress-bar {
  display: flex;
  height: 3px;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
  font-size: 14px;

  .shepherd-step-segment {
    width: 100%;
    height: 100%;
    margin: 0 2px;
    background: $rc2-grey-100;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    &.active {
      background-color: $rc2-color-primary;
    }
  }
}

.shepherd-cancel-icon {
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  color: $rc2-color-primary;
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  transition: color 0.5s ease;

  &:hover {
    color: rgba(0, 0, 0, 75%);
  }
}

.shepherd-has-title .shepherd-content {
  .shepherd-cancel-icon {
    color: $rc2-color-primary;

    &:hover {
      color: $rc2-color-primary;
    }
  }

  .shepherd-header {
    padding: 1em;
    border-bottom: solid 1px $rc2-grey-100;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.shepherd-title {
  display: flex;
  width: 90%;
  flex: 1 0 auto;
  padding: 0;
  margin: 0;

  /* TODO: After removing the lines bellow, the title gets too big. Would be nice to have a <h5> element */
  color: rgba(0, 0, 0, 75%);
  font-size: 1rem;
  font-weight: 400;
}

.shepherd-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 0.75rem 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 2em;
}

.shepherd-text {
  padding: 1em;

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.shepherd-content {
  padding: 0;
  border-radius: 5px;
  outline: none;
}

.shepherd-element {
  z-index: 9999;
  width: 100%;
  max-width: 400px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 20%);
  opacity: 0;
  outline: none;
  transition:
    opacity 0.3s,
    visibility 0.3s;
  visibility: hidden;
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.shepherd-element,
.shepherd-element *,
.shepherd-element ::after,
.shepherd-element ::before {
  box-sizing: border-box;
}

.shepherd-arrow,
.shepherd-arrow::before {
  position: absolute;
  z-index: -1;
  width: 8px;
  height: 8px;
}

.shepherd-arrow::before {
  background: #fff;
  content: '';
  transform: rotate(45deg);
}

.shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  bottom: -4px;
}

.shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
  top: -4px;
}

.shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
  right: -4px;
}

.shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
  left: -4px;
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}

// .shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow::before {
//   background-color: #e6e6e6;
// }

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition:
    all 0.3s ease-out,
    height 0ms 0.3s,
    opacity 0.3s 0ms;

  &.shepherd-modal-is-visible {
    height: 100vh;
    opacity: 0.5;
    transform: translateZ(0);
    transition:
      all 0.3s ease-out,
      height 0s 0s,
      opacity 0.3s 0s;

    path {
      pointer-events: all;
      fill: $rc2-backdrop;
    }
  }
}
