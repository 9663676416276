$small-vp-padding: 8px;
$small-row-margin: -8px;
$big-vp-padding: 12px;
$big-row-margin: -12px;
$sm-breakpoint: 768px;
$md-breakpoint: 1024px;
$lg-breakpoint: 1440px;
$xl-breakpoint: 1920px;
$container-padding: 32px;

@mixin col-quarter($number) {
  position: relative;
  width: (8.33333333% * $number) + 2.083333333325%;
  min-height: 1px;
  float: left;
}

@mixin col-half($number) {
  position: relative;
  width: (8.33333333% * $number) + 4.16666665%;
  min-height: 1px;
  float: left;
}

@mixin col-3-quarters($number) {
  position: relative;
  width: (8.33333333% * $number) + 6.249999999975%;
  min-height: 1px;
  float: left;
}

.col {
  position: relative;
  min-height: 1px;
  float: left;
}

.col-md-0-25 {
  @include col-quarter(0);
}

.col-md-0-5 {
  @include col-half(0);
}

.col-md-0-75 {
  @include col-3-quarters(0);
}

.col-md-1 {
  position: relative;
}

.col-md-1-25 {
  @include col-quarter(1);
}

.col-md-1-5 {
  @include col-half(1);
}

.col-md-1-75 {
  @include col-3-quarters(1);
}

.col-md-2-25 {
  @include col-quarter(2);
}

.col-md-2-5 {
  @include col-half(2);
}

.col-md-2-75 {
  @include col-3-quarters(2);
}

.col-md-3-25 {
  @include col-quarter(3);
}

.col-md-3-5 {
  @include col-half(3);
}

.col-md-3-75 {
  @include col-3-quarters(3);
}

.col-md-4-25 {
  @include col-quarter(4);
}

.col-md-4-5 {
  @include col-half(4);
}

.col-md-4-75 {
  @include col-3-quarters(4);
}

.col-md-5-25 {
  @include col-quarter(5);
}

.col-md-5-5 {
  @include col-half(5);
}

.col-md-5-75 {
  @include col-3-quarters(5);
}

.col-md-6-25 {
  @include col-quarter(6);
}

.col-md-6-5 {
  @include col-half(6);
}

.col-md-6-75 {
  @include col-3-quarters(6);
}

.col-md-7-25 {
  @include col-quarter(7);
}

.col-md-7-5 {
  @include col-half(7);
}

.col-md-7-75 {
  @include col-3-quarters(7);
}

.col-md-8-25 {
  @include col-quarter(8);
}

.col-md-8-5 {
  @include col-half(8);
}

.col-md-8-75 {
  @include col-3-quarters(8);
}

.col-md-9-25 {
  @include col-quarter(9);
}

.col-md-9-5 {
  @include col-half(9);
}

.col-md-9-75 {
  @include col-3-quarters(9);
}

.col-md-10-25 {
  @include col-quarter(10);
}

.col-md-10-5 {
  @include col-half(10);
}

.col-md-10-75 {
  @include col-3-quarters(10);
}

.col-md-11-25 {
  @include col-quarter(11);
}

.col-md-11-5 {
  @include col-half(11);
}

.col-md-11-75 {
  @include col-3-quarters(11);
}

td.mat-mdc-cell,
td.mat-mdc-footer-cell,
th.mat-mdc-header-cell {
  padding: 0 $big-vp-padding !important;
}

[class*='col-md-'],
[class*='col-sm-'] {
  padding-right: $small-vp-padding;
  padding-left: $small-vp-padding;
}

.row {
  margin-right: $small-row-margin;
  margin-left: $small-row-margin;
}

// Overrides bootstrap styles to avoid unexpected behaviors with non col elements
// in rows
.row > *:not([class*='col-']) {
  flex-shrink: unset;
  width: inherit;
  max-width: none;
  padding-right: unset;
  padding-left: unset;
  margin-top: unset;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-right: $small-vp-padding;
  padding-left: $small-vp-padding;
}

@media (min-width: $sm-breakpoint) {
  [class*='col-md-'],
  [class*='col-sm-'] {
    padding-right: $big-vp-padding;
    padding-left: $big-vp-padding;
  }

  .row {
    margin-right: $big-row-margin;
    margin-left: $big-row-margin;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-right: $container-padding;
    padding-left: $container-padding;
  }
}
