@import './../globals/colors';
@import './../globals/fonts';

.rc2-form {
  &--feedback {
    &--invalid {
      color: $rc2-red-800-primary;
      font-family: $rc2-font-bold, 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 14px;
      letter-spacing: 0.47px;
      line-height: 27px;
    }
  }

  &--input {
    &--disable {
      cursor: not-allowed;
      opacity: 80%;
      pointer-events: none;
    }
  }
}
