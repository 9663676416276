@import './../globals/colors';
@import './../globals/spacings';
@import './../globals/utils';
@import './../globals/fonts.scss';

$rc2-tooltip-medium-size: 240px;
$rc2-tooltip-large-size: 360px;

%rc2-tooltip {
  max-width: $rc2-tooltip-medium-size;
  padding: $rc2-spacing-3xs $rc2-spacing-xxs;
  border-radius: 2px;
  margin: $rc2-spacing-xxs;
  background-color: $rc2-grey-800-primary;
  font-size: 14px;
  line-height: $rc2-spacing-m;
  text-align: left;
}

%rc2-tooltip-error {
  background-color: $rc2-red-400-primary;
  color: $rc2-red-800-primary;
}

%rc2-tooltip-large {
  max-width: $rc2-tooltip-large-size;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: $rc2-font-regular;

  /* stylelint-disable-next-line selector-class-pattern */
  .mdc-tooltip__surface {
    @extend %rc2-tooltip;
  }

  &.rc2-tooltip-error {
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-tooltip__surface {
      @extend %rc2-tooltip-error;
    }

    &--large {
      /* stylelint-disable-next-line selector-class-pattern */
      .mdc-tooltip__surface {
        @extend %rc2-tooltip-large;
      }
    }
  }

  &.rc2-tooltip--large {
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-tooltip__surface {
      @extend %rc2-tooltip-large;
    }
  }

  &.rc2-tooltip--line-break {
    /* stylelint-disable-next-line selector-class-pattern */
    .mdc-tooltip__surface {
      white-space: pre-line;
    }
  }

  &.rc2-tooltip-filters {
    max-width: $rc2-tooltip-medium-size;
    padding: $rc2-spacing-3xs $rc2-spacing-xxs;
    border-radius: 2px;
    margin: $rc2-spacing-xs;
    background-color: $rc2-grey-800-primary;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
  }
}
