@import './../globals/colors';

.rc2-icon {
  &--cursor {
    &--pointer {
      cursor: pointer;
    }

    &--not-allowed {
      cursor: not-allowed;
    }
  }

  &--rotate {
    &--45 {
      transform: rotate(45deg);
    }

    &--90 {
      transform: rotate(90deg);
    }

    &--135 {
      transform: rotate(135deg);
    }

    &--180 {
      transform: rotate(180deg);
    }

    &--270 {
      transform: rotate(270deg);
    }
  }

  &--bold {
    font-weight: bold;
  }

  &--size {
    &--16 {
      font-size: 16px;
    }

    &--18 {
      font-size: 18px;
    }

    &--20 {
      font-size: 20px;
    }

    &--22 {
      font-size: 22px;
    }

    &--24 {
      font-size: 24px;
    }

    &--26 {
      font-size: 26px;
    }

    &--30 {
      font-size: 30px;
    }

    &--36 {
      font-size: 36px;
    }

    &--40 {
      font-size: 40px;
    }

    &--42 {
      font-size: 42px;
    }

    &--52 {
      font-size: 52px;
    }
  }

  &--color {
    &--grey {
      color: $rc2-grey-400-secondary;

      &--1000 {
        color: $rc2-grey-1000;
      }

      &--900 {
        color: $rc2-grey-900;
      }

      &--500 {
        color: $rc2-grey-500;
      }

      &--400-secondary {
        color: $rc2-grey-400-secondary;
      }

      &--200-tertiary {
        color: $rc2-grey-200-tertiary;
      }
    }

    &--white {
      color: $rc2-white;
    }

    &--primary {
      color: $rc2-color-primary;
    }

    &--red {
      color: $rc2-red-800-primary;
    }

    &--orange {
      color: $rc2-orange-800-primary;
    }

    &--green {
      color: $rc2-green-800-primary;
    }

    &--black {
      color: $rc2-black;
    }
  }

  &--bg {
    &--green {
      background: $rc2-green-800-primary;
    }

    &--white {
      background: $rc2-white;
    }

    &--blue {
      background: $rc2-color-primary;
    }

    &--red {
      background: $rc2-red-800-primary;
    }

    &--grey {
      background: $rc2-grey-800-primary;
    }

    &--orange {
      background: $rc2-orange-800-primary;
    }

    &--highlight {
      background: $rc2-grey-900;
    }
  }

  &--data-table {
    font-size: 26px;

    &--notification {
      color: $rc2-red-800-primary;
    }

    &--topic {
      color: $rc2-red-800-primary;
    }

    &--agendaitem {
      color: $rc2-orange-800-primary;
    }

    &--action {
      color: $rc2-black;
    }

    &--clearing {
      color: $rc2-color-primary;
    }

    &--criteria {
      color: $rc2-black;
    }

    &--protocol {
      color: $rc2-color-primary;
    }

    &--verification {
      color: $rc2-orange-800-primary;
    }

    &--meeting {
      color: $rc2-green-800-primary;
    }

    &--part-order {
      color: $rc2-color-primary;
    }

    &--user {
      color: $rc2-color-secondary;
    }

    &--user-order {
      color: $rc2-petrol-200-tertiary;
    }

    &--gremium {
      color: $rc2-color-primary;
    }
  }

  &--state {
    &--notification {
      &--draft {
        color: $rc2-red-800-primary;
      }

      &--discarded {
        color: $rc2-grey-200-tertiary;
      }

      &--rework-p,
      &--rework-gs,
      &--review {
        color: $rc2-orange-800-primary;
      }

      &--approved,
      &--accepted,
      &--linked {
        color: $rc2-green-800-primary;
      }
    }

    &--topic,
    &--clearing {
      &--draft {
        color: $rc2-red-800-primary;
      }

      &--decision {
        color: $rc2-green-800-primary;
      }

      &--completed {
        color: $rc2-green-800-primary;
      }

      &--progress,
      &--planning {
        color: $rc2-orange-800-primary;
      }
    }

    &--meeting {
      &--draft {
        color: $rc2-red-800-primary;
      }

      &--invited {
        color: $rc2-orange-800-primary;
      }

      &--canceled {
        color: $rc2-grey-200-tertiary;
      }

      &--completed {
        color: $rc2-green-800-primary;
      }
    }

    &--agenda-item {
      &--canceled {
        color: $rc2-grey-200-tertiary;
      }

      &--no-protocol {
        color: $rc2-red-800-primary;
      }

      &--protocol,
      &--approved {
        color: $rc2-green-800-primary;
      }
    }

    &--verification {
      &--draft {
        color: $rc2-red-800-primary;
      }

      &--progress {
        color: $rc2-orange-800-primary;
      }

      &--completed {
        color: $rc2-green-800-primary;
      }

      &--unanswered {
        color: $rc2-orange-800-primary;
      }

      &--declined {
        color: $rc2-red-800-primary;
      }
    }

    &--committee {
      &--inactive {
        color: $rc2-red-800-primary;
      }

      &--active {
        color: $rc2-green-800-primary;
      }
    }

    &--campaign {
      &--draft,
      &--in-preparation,
      &--stopped {
        color: $rc2-red-800-primary;
      }

      &--ready,
      &--released {
        color: $rc2-orange-800-primary;
      }

      &--active,
      &--closed {
        color: $rc2-green-800-primary;
      }
    }

    &--partner-data {
      &--deleted,
      &--complete-lock {
        color: $rc2-red-800-primary;
      }

      &--in-preparation {
        color: $rc2-orange-800-primary;
      }

      &--approved,
      &--blocked {
        color: $rc2-green-800-primary;
      }
    }
  }
}
