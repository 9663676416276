@import './../globals/colors';
@import './../globals/fonts';

$rc2-select-height: 36px;
$rc2-select-option-text-height: 20px;

.select-panel-option {
  color: $rc2-grey-400-secondary;
}

.mat-mdc-select-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background: $rc2-grey-50;
  color: $rc2-black;
}

.mat-mdc-select-panel .mat-mdc-optgroup-label,
.mat-mdc-select-panel .mat-mdc-option {
  color: $rc2-grey-1000;
}

.mat-mdc-option:focus:not(.mat-mdc-option-disabled),
.mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
  background-color: none;
  color: $rc2-grey-900;
}

mat-option.mat-mdc-option {
  padding: 8px 16px;
  min-height: 42px;
}

mat-option.mat-mdc-option:not(.mat-mdc-option-multiple) .mat-pseudo-checkbox {
  display: none;
}

mat-option.mat-mdc-option.searchable .mdc-list-item__primary-text input {
  top: -2px;
}

mat-option.mat-mdc-option .mdc-list-item__primary-text {
  flex-grow: 1;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  line-height: initial;
  overflow-wrap: break-word;
  white-space: initial;
}

.mat-mdc-select-trigger {
  color: $rc2-black;
  letter-spacing: 0.2px;
}
