@import './../globals/colors';
@import './../globals/spacings';

.rc2-page-wrapper {
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  padding: $rc2-spacing-l $rc2-spacing-l $rc2-spacing-4xl;
  margin: $rc2-spacing-xxl auto $rc2-spacing-4xl;
  background-color: $rc2-white !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 4px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.rc2-overview-wrapper {
  overflow: hidden;
  padding: $rc2-spacing-l $rc2-spacing-l $rc2-spacing-4xl;
  margin: $rc2-spacing-l $rc2-spacing-l $rc2-spacing-4xl;
  background-color: $rc2-white !important;
}
