@import '../globals/fonts';
@import '../globals/typography';

h1,
.h1 {
  @include heading1;
}

h2,
.h2 {
  @include heading2;
}

h3,
.h3 {
  @include heading3;
}

h4,
.h4 {
  @include heading4;
}

h5,
.h5 {
  @include heading5;
}

h6,
.h6 {
  @include heading6;
}

.rc2-font {
  &--body {
    &--xs {
      @include body-xs;
    }

    &--s {
      @include body-s;
    }

    &--m {
      @include body-m;
    }

    &--l {
      @include body-l;
    }

    &--xl {
      @include body-xl;
    }
  }

  &--link {
    &--m {
      @include link-m;
    }

    &--l {
      @include link-l;
    }
  }

  &--family {
    &--regular {
      font-family: $rc2-font-regular;
    }

    &--light {
      font-family: $rc2-font-light;
    }

    &--bold {
      font-family: $rc2-font-bold;
    }
  }

  &--bold {
    @include font-bold;
  }

  &--regular {
    @include font-regular;
  }

  &--light {
    @include font-light;
  }

  &--size {
    &--9 {
      font-size: 9px;
    }

    &--10 {
      font-size: 10px;
    }

    &--12 {
      font-size: 12px;
    }

    &--14 {
      font-size: 14px;
    }

    &--16 {
      font-size: 16px;
    }

    &--18 {
      font-size: 18px;
    }

    &--20 {
      font-size: 20px;
    }

    &--22 {
      font-size: 22px;
    }

    &--24 {
      font-size: 24px;
    }

    &--30 {
      font-size: 30px;
    }

    &--36 {
      font-size: 36px;
    }

    &--40 {
      font-size: 40px;
    }

    &--42 {
      font-size: 42px;
    }

    &--52 {
      font-size: 52px;
    }
  }
}
