@import './../globals/colors';

.rc2-color {
  &--inherit {
    color: inherit;
  }

  &--primary {
    color: $rc2-color-primary;
  }

  &--secondary {
    color: $rc2-color-secondary;
  }

  &--tertiary {
    color: $rc2-color-tertiary;
  }

  &--error {
    color: $rc2-color-danger;
  }

  &--danger {
    color: $rc2-color-danger;
  }

  &--attention {
    color: $rc2-color-caution;
  }

  &--caution {
    color: $rc2-color-caution;
  }

  &--success {
    color: $rc2-color-success;
  }

  &--info {
    color: $rc2-color-info;
  }

  &--grey-1000 {
    color: $rc2-grey-1000;
  }

  &--grey-900 {
    color: $rc2-grey-900;
  }

  &--grey-800-primary {
    color: $rc2-grey-800-primary;
  }

  &--grey-700 {
    color: $rc2-grey-700;
  }

  &--grey-600 {
    color: $rc2-grey-600;
  }

  &--grey-500 {
    color: $rc2-grey-500;
  }

  &--grey-400-secondary {
    color: $rc2-grey-400-secondary;
  }

  &--grey-200-tertiary {
    color: $rc2-grey-200-tertiary;
  }

  &--grey-100 {
    color: $rc2-grey-100;
  }

  &--petrol-1000 {
    color: $rc2-petrol-1000;
  }

  &--petrol-900 {
    color: $rc2-petrol-900;
  }

  &--petrol-800-primary {
    color: $rc2-petrol-800-primary;
  }

  &--petrol-700 {
    color: $rc2-petrol-700;
  }

  &--petrol-600 {
    color: $rc2-petrol-600;
  }

  &--petrol-500 {
    color: $rc2-petrol-500;
  }

  &--petrol-400-secondary {
    color: $rc2-petrol-400-secondary;
  }

  &--petrol-300 {
    color: $rc2-petrol-300;
  }

  &--petrol-200-tertiary {
    color: $rc2-petrol-200-tertiary;
  }

  &--petrol-100 {
    color: $rc2-petrol-100;
  }

  &--black {
    color: $rc2-black;
  }
}

.rc2-bg {
  &--inherit {
    background-color: inherit;
  }

  &--primary {
    background-color: $rc2-color-primary;
  }

  &--secondary {
    background-color: $rc2-color-secondary;
  }

  &--tertiary {
    background-color: $rc2-color-tertiary;
  }

  &--error {
    background-color: $rc2-color-danger;
  }

  &--danger {
    background-color: $rc2-color-danger;
  }

  &--attention {
    background-color: $rc2-color-caution;
  }

  &--caution {
    background-color: $rc2-color-caution;
  }

  &--success {
    background-color: $rc2-color-success;
  }

  &--info {
    background-color: $rc2-color-info;
  }

  &--grey-1000 {
    background-color: $rc2-grey-1000;
  }

  &--grey-900 {
    background-color: $rc2-grey-900;
  }

  &--grey-800-primary {
    background-color: $rc2-grey-800-primary;
  }

  &--grey-700 {
    background-color: $rc2-grey-700;
  }

  &--grey-600 {
    background-color: $rc2-grey-600;
  }

  &--grey-400-secondary {
    background-color: $rc2-grey-400-secondary;
  }

  &--grey-200-tertiary {
    background-color: $rc2-grey-200-tertiary;
  }

  &--grey-800-primary {
    background-color: $rc2-grey-100;
  }

  &--grey-100 {
    background-color: $rc2-grey-100;
  }

  &--black {
    background-color: $rc2-black;
  }

  &--petrol-1000 {
    background-color: $rc2-petrol-1000;
  }

  &--petrol-900 {
    background-color: $rc2-petrol-900;
  }

  &--petrol-800-primary {
    background-color: $rc2-petrol-800-primary;
  }

  &--petrol-700 {
    background-color: $rc2-petrol-700;
  }

  &--petrol-600 {
    background-color: $rc2-petrol-600;
  }

  &--petrol-500 {
    background-color: $rc2-petrol-500;
  }

  &--petrol-400-secondary {
    background-color: $rc2-petrol-400-secondary;
  }

  &--petrol-300 {
    background-color: $rc2-petrol-300;
  }

  &--petrol-200-tertiary {
    background-color: $rc2-petrol-200-tertiary;
  }

  &--petrol-100 {
    background-color: $rc2-petrol-100;
  }

  &--petrol-50 {
    background-color: $rc2-petrol-50;
  }

  &--white {
    background-color: $rc2-white;
  }
}
