$rc2-petrol-1000: #00354d;
$rc2-petrol-900: #004666;
$rc2-petrol-800-primary: #20607e;
$rc2-petrol-700: #35718e;
$rc2-petrol-600: #4a849f;
$rc2-petrol-500: #5f95af;
$rc2-petrol-400-secondary: #80b0c8;
$rc2-petrol-300: #a4c8d8;
$rc2-petrol-200-tertiary: #c6dfe7;
$rc2-petrol-100: #eaf4f7;
$rc2-petrol-50: #f2f8fa;
$rc2-black: #000;
$rc2-white: #fff;
$rc2-red-800-primary: #da0c1f;
$rc2-red-400-primary: #f3ccd0;
$rc2-orange-800-primary: #ff9b00;
$rc2-orange-400-primary: #f9e6cb;
$rc2-green-800-primary: #64a844;
$rc2-grey-1000: #1b1e1f;
$rc2-grey-900: #34393b;
$rc2-grey-800-primary: #4c5356;
$rc2-grey-700: #636a62;
$rc2-grey-600: #7a8084;
$rc2-grey-500: #91979c;
$rc2-grey-400-secondary: #a8adb3;
$rc2-grey-300: #cacdd2;
$rc2-grey-200-tertiary: #dfe2e6;
$rc2-grey-100: #f0f1f2;
$rc2-grey-50: #f7f7f8;
$rc2-petrol-900: #004666;
$rc2-red-1000: #800712;
$rc2-red-900: #a60918;
$rc2-green-1000: #46752f;
$rc2-green-900: #558f3a;
$rc2-green-800: #ddedd5;
$rc2-orange-1000: #bf7400;
$rc2-orange-900: #e68b00;
$rc2-backdrop: rgba($rc2-petrol-800-primary, 0.8);
$rc2-color-primary: $rc2-petrol-800-primary;
$rc2-color-secondary: $rc2-petrol-400-secondary;
$rc2-color-tertiary: $rc2-petrol-200-tertiary;
$rc2-color-info: $rc2-petrol-400-secondary;
$rc2-color-danger: $rc2-red-800-primary;
$rc2-color-caution: $rc2-orange-800-primary;
$rc2-color-success: $rc2-green-800-primary;
$rc2-color-disabled: $rc2-grey-200-tertiary;
