@import './../globals/colors';

@mixin border-bottom {
  border-bottom: 1px solid $rc2-white;
}

.rc2-border {
  border: 1px solid $rc2-white;
  border-radius: 2px;

  &--round {
    border-radius: 50%;
  }

  &--primary {
    border-color: $rc2-color-primary;
  }

  &--secondary {
    border-color: $rc2-color-secondary;
  }

  &--tertiary {
    border-color: $rc2-color-tertiary;
  }

  &--error {
    border-color: $rc2-color-danger;

    &:hover {
      border-color: $rc2-color-caution;
    }
  }

  &--danger {
    border-color: $rc2-color-danger;
  }

  &--attention {
    border-color: $rc2-color-caution;
  }

  &--caution {
    border-color: $rc2-color-caution;
  }

  &--success {
    border-color: $rc2-color-success;
  }

  &--info {
    border-color: $rc2-color-info;
  }

  &--grey {
    &--200-tertiary {
      border-color: $rc2-grey-200-tertiary;
    }
    &--300 {
     border-color: $rc2-grey-300;
    }
  }

  &--bottom {
    @include border-bottom;

    &--primary {
      @include border-bottom;

      border-color: $rc2-color-primary;
    }

    &--secondary {
      @include border-bottom;

      border-color: $rc2-color-secondary;
    }

    &--tertiary {
      @include border-bottom;

      border-color: $rc2-color-tertiary;
    }

    &--error {
      @include border-bottom;

      border-color: $rc2-color-danger;

      &:hover {
        @include border-bottom;

        border-color: $rc2-color-caution;
      }
    }

    &--danger {
      @include border-bottom;

      border-color: $rc2-color-danger;
    }

    &--attention {
      @include border-bottom;

      border-color: $rc2-color-caution;
    }

    &--caution {
      @include border-bottom;

      border-color: $rc2-color-caution;
    }

    &--success {
      @include border-bottom;

      border-color: $rc2-color-success;
    }

    &--info {
      @include border-bottom;

      border-color: $rc2-color-info;
    }

    &--grey {
      &--200-tertiary {
        @include border-bottom;

        border-color: $rc2-grey-200-tertiary;
      }
    }
  }
}
