@use '../material-theme/vw-theme' as vwTheme;

@import '../vwag/vwag';
@import 'quill/dist/quill.bubble';
@import 'quill/dist/quill.snow';
@import 'quill/dist/quill.core';
@import 'bootstrap/dist/css/bootstrap-grid.min';

@import 'rc2-icon-appendix';
@import 'rc2-wrapper';
@import 'rc2-border';
@import 'rc2-form';
@import 'rc2-font-styles';
@import 'rc2-icon-styles';
@import 'rc2-color';
@import 'rc2-spacing';
@import 'rc2-grid';
@import 'rc2-tooltip';
@import 'rc2-buttons';
@import 'rc2-helper-tour';

/* ------  styles for the overlay module --------- */
@import 'rc2-overlay-styles';

/* ------  styles for the select module --------- */
@import 'rc2-select';

/* ------  styles for components --------- */
@import './../components';

@import './../globals/typography';
@import './../globals/colors';
@import './../globals/cdk';

// fix for vwag css
html {
  overflow-y: unset;
}

// Fix for new bootstrap 5
*,
::after,
::before {
  box-sizing: border-box;
}

/*
- these are general styles that currently do not belong to any other module.
- all the styling and classes here(in rc2-main.scss) should be later refactored and moved from the rc2-main.scss.
- we just write them CURRENTLY here so that it is easy to be tracked and later refactored.
*/
.rc2-position {
  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }
}

.rc2-cursor {
  &--pointer {
    &:hover {
      cursor: pointer;
    }
  }

  &--not-allowed {
    &:hover {
      cursor: not-allowed;
    }
  }
}

.rc2-overflow {
  &--hidden {
    overflow: hidden;
  }
}

.rc2-pad {
  &--4 {
    --deprecated: 'WARNING: Use .rc2-pad--4 instead of .rc2-pad--3xs';

    padding: $rc2-spacing-3xs;
  }

  &--left {
    &--4 {
      --deprecated: 'WARNING: Use .rc2-pad--left--4 instead of .rc2-pad--left--3xs';

      padding-left: $rc2-spacing-3xs;
    }
  }

  &--right {
    &--4 {
      --deprecated: 'WARNING: Use .rc2-pad-right--4 instead of .rc2-pad--right--3xs';

      padding-right: $rc2-spacing-3xs;
    }
  }
}

.rc2-align-items {
  &--center {
    align-items: center;
  }

  &--flex-end {
    align-items: flex-end;
  }

  &--flex-start {
    align-items: flex-start;
  }

  &--baseline {
    align-items: baseline;
  }
}

.rc2-vertical-align {
  &--middle {
    vertical-align: middle;
  }
}

.rc2-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rc2-object-table--ellipsis {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.inline-block {
  display: inline-block;
}

.rc2-display {
  &--inline {
    display: inline;

    &--block {
      display: inline-block;
    }
  }

  &--block {
    display: block;
  }

  &--none {
    display: none;
  }

  &--flex {
    display: flex;
  }

  &--flex-column {
    display: flex;
    flex-direction: column;
  }

  &--grid {
    display: grid;
  }
}

.rc2-line {
  &--height {
    &--0 {
      line-height: 0px;
    }

    &--12 {
      line-height: 12px;
    }

    &--14 {
      line-height: 14px;
    }

    &--15 {
      line-height: 15px;
    }

    &--26 {
      line-height: 26px;
    }

    &--28 {
      line-height: 28px;
    }
  }
}

.rc2-text-align {
  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }
}

.rc2-word-break {
  &--all {
    word-break: break-all;
  }
}

.rc2-justify {
  &--left {
    justify-content: left;
  }

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  &--flex-start {
    justify-content: flex-start;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }
}

.rc2-logo {
  &--img {
    width: 30px;
    height: 30px;
  }

  &--center {
    margin: 0 auto;
  }
}

/* ------------ Text Cell Dynamic style for normal-ellipsis ----------- */
// increase specificity for normal-ellipsis class to override rc2-object-table--ellipsis class
text-cell div.normal-ellipsis {
  display: block;
  white-space: nowrap;
}

// increase specificity for img logo to overcome the default styling that is was originally applied directly to the img tag element
img.rc2-logo--img {
  width: 30px;
  height: 30px;
}

.rc2-full {
  &--width {
    width: 100%;
  }

  &--height {
    height: 100%;
  }
}

// vwag.css sets styles for ".accordion li" which is undesired inside quill-view
.accordion quill-view li {
  display: block !important;
  border: 0 !important;
}

.section-spacing {
  margin-bottom: $rc2-spacing-5xl;
}

.mat-expansion-panel {
  box-sizing: inherit !important;

  &:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
  }
}

tr.mat-mdc-header-row {
  height: auto !important;
}

.mat-button-toggle-label-content {
  @include body-m;

  padding: 0 $rc2-spacing-xxs !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba($rc2-grey-300, 0.8) transparent;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba($rc2-grey-300, 0.8);
  border-radius: 8px;
}
