/* stylelint-disable function-parentheses-space-inside */
/* stylelint-disable scss/at-mixin-argumentless-call-parentheses */
/* stylelint-disable font-family-name-quotes */
/* stylelint-disable value-keyword-case */

@use '@angular/material' as mat;
@use 'sass:map';

@import '../globals/fonts';
@import '../globals/colors';
@import '../globals/spacings';
@import '../globals/typography';


@include mat.core();
@include mat.strong-focus-indicators();

// VW Colors
$dark-primary-text: rgba($rc2-black, 0.87);
$dark-secondary-text: rgba($rc2-black, 0.54);
$dark-disabled-text: rgba($rc2-black, 0.38);
$dark-dividers: rgba($rc2-black, 0.12);
$dark-focused: rgba($rc2-black, 0.12);
$light-primary-text: $rc2-white;
$light-secondary-text: rgba($rc2-white, 0.7);
$light-disabled-text: rgba($rc2-white, 0.5);
$light-dividers: rgba($rc2-white, 0.12);
$light-focused: rgba($rc2-white, 0.12);

// Blue
$vw-blue-palette: (
  50: $rc2-petrol-50,
  100: $rc2-petrol-100,
  200: $rc2-petrol-200-tertiary,
  300: $rc2-petrol-300,
  400: $rc2-petrol-400-secondary,
  500: $rc2-petrol-500,
  600: $rc2-petrol-600,
  700: $rc2-petrol-700,
  800: $rc2-petrol-800-primary,
  900: $rc2-petrol-900,
  1000: $rc2-petrol-1000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

// Grey
$vw-grey-palette: (
  50: $rc2-grey-50,
  100: $rc2-grey-100,
  200: $rc2-grey-200-tertiary,
  300: $rc2-grey-300,
  400: $rc2-grey-400-secondary,
  500: $rc2-grey-500,
  600: $rc2-grey-600,
  700: $rc2-grey-700,
  800: $rc2-grey-800-primary,
  900: $rc2-grey-900,
  1000: $rc2-grey-1000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    1000: $light-primary-text,
  )
);

// Foreground palette
$vw-foreground-palette: (
  base:              $rc2-black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($rc2-black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         $rc2-black,
  hint-text:         $dark-disabled-text,
  secondary-text:    $dark-secondary-text,
  icon:              rgba($rc2-black, 0.54),
  icons:             rgba($rc2-black, 0.54),
  text:              rgba($rc2-black, 0.87),
  slider-min:        rgba($rc2-black, 0.87),
  slider-off:        rgba($rc2-black, 0.26),
  slider-off-active: rgba($rc2-black, 0.38),
);

// Background palette.
$vw-background-palette: (
  status-bar: map.get($vw-grey-palette, 300),
  app-bar:    map.get($vw-grey-palette, 100),
  background: map.get($vw-grey-palette, 50),
  hover:      rgba($rc2-black, 0.04),
  card:       $rc2-white,
  dialog:     $rc2-white,
  disabled-button: rgba($rc2-black, 0.12),
  raised-button: $rc2-white,
  focused-button: $dark-focused,
  selected-button: map.get($vw-grey-palette, 300),
  selected-disabled-button: map.get($vw-grey-palette, 400),
  disabled-button-toggle: map.get($vw-grey-palette, 200),
  unselected-chip: map.get($vw-grey-palette, 300),
  disabled-list-option: map.get($vw-grey-palette, 200),
  tooltip: map.get($vw-grey-palette, 700),
);

// VW Palette
$vw-theme-primary: mat.define-palette($vw-blue-palette);
$vw-theme-accent: mat.define-palette($vw-blue-palette, 800, 400, 200);
$vw-theme-grey: mat.define-palette($vw-grey-palette);

// VW Typography
$vw-light-typography: mat.define-typography-config($font-family: $rc2-font-light);
$vw-regular-typography: mat.define-typography-config($font-family: $rc2-font-regular);
$vw-bold-typography: mat.define-typography-config($font-family: $rc2-font-bold);
$vw-default-typography: mat.define-typography-config(
  $font-family: $rc2-font-regular,
  $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.define-typography-level(34px, 40px, 400),
  $headline-5: mat.define-typography-level(24px, 32px, 400),
  $headline-6: mat.define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.define-typography-level(15px, 24px, 400),
  $body-1: mat.define-typography-level(14px, 24px, 500),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $caption: mat.define-typography-level(inherit, 1.125, 400),
);

// VW Theme
$vw-theme: mat.define-light-theme((
  color: (
    primary: $vw-theme-primary,
    accent: $vw-theme-accent,
  ),
  typography: $vw-default-typography,
  density: 0,
));

@include mat.all-component-themes($vw-theme);
@include mat.typography-hierarchy($vw-default-typography);
@include mat.strong-focus-indicators-theme($vw-theme);


/* ------------------------- global styles ---------------------------- */

html,
body { height: 100%; }

body {
  margin: 0;
  font-family: $rc2-font-regular, 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
