@import './fonts';

@mixin font-bold {
  font-family: $rc2-font-bold;
}

@mixin font-regular {
  font-family: $rc2-font-regular;
}

@mixin font-light {
  font-family: $rc2-font-regular;
}

@mixin heading-common {
  @include font-bold;

  letter-spacing: 0.2px;
  line-height: 1.5;
}

@mixin body-common {
  @include font-regular;

  letter-spacing: 0.2px;
}

@mixin link-common {
  @include font-bold;

  color: $rc2-petrol-800-primary;
  letter-spacing: 0.2px;

  &:hover {
    color: $rc2-petrol-400-secondary;
    text-decoration: underline;
  }
}

@mixin heading1 {
  @include heading-common;

  font-size: 40px;
}

@mixin heading2 {
  @include heading-common;

  font-size: 32px;
}

@mixin heading3 {
  @include heading-common;

  font-size: 28px;
}

@mixin heading4 {
  @include heading-common;

  font-size: 24px;
}

@mixin heading5 {
  @include heading-common;

  font-size: 20px;
}

@mixin heading6 {
  @include heading-common;

  font-size: 18px;
}

@mixin body-xs {
  @include body-common;

  font-size: 10px;
  line-height: 1.4;
}

@mixin body-s {
  @include body-common;

  font-size: 12px;
  line-height: 1.5;
}

@mixin body-m {
  @include body-common;

  font-size: 14px;
  line-height: 1.43;
}

@mixin body-l {
  @include body-common;

  font-size: 16px;
  line-height: 1.5;
}

@mixin body-xl {
  @include body-common;

  font-size: 18px;
  line-height: 1.33;
}

@mixin link-m {
  @include link-common;

  font-size: 14px;
  line-height: 1.43;
}

@mixin link-l {
  @include link-common;

  font-size: 16px;
  line-height: 1.5;
}
